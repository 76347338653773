.color-game-div {
    height: 89vh;
    /* width: 50%; */
    overflow-y: scroll;

}

.color-game-div::-webkit-scrollbar {
    display: none;
}

.color-game-h {
    background-color: #2C3D51;
    width: 100%;
    height: 39px;
}

.color-game-h p {
    color: white;
    font-family: "mukta";
    font-size: 18px;
    font-weight: 500;
}

.color-game-card {
    height: 255px;
    width: 100%;
    border-radius: 6px;
    background-color: rgb(206, 206, 221);
}

.Green-button {
    background-image: linear-gradient(to right, #3d8b13 0%, #25c321 51%, #6c7b65 100%);
    box-shadow: rgb(0 194 130 / 40%) 0px 0px 8px;
    margin: 5px;
    padding: 5px 30px;
    text-align: center;
    height: 55px;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: none;
    font-family: "mukta";
    font-weight: bolder;


}

.violet-button {
    background-image: linear-gradient(to right, #9D50BB 0%, #6E48AA 51%, #9D50BB 100%);
    box-shadow: rgb(155 39 176 / 40%) 0px 0px 8px;
    margin: 5px;
    padding: 5px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    xbox-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: none;
    height: 55px;
    font-family: "mukta";
    font-weight: bolder;
}

.red-button {
    background-image: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
    box-shadow: rgb(250 60 9 / 40%) 0px 0px 8px;
    margin: 5px;
    padding: 5px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    height: 55px;
    font-family: "mukta";
    font-weight: bolder;
    border: none;
}

.paymettext1 {
    padding-left: 10px;
}

.labels {
    color: white;
    font-size: 15px;
}

.violet-red-btn {
    background: linear-gradient(to right bottom, rgb(141 77 181) 50%, rgb(227 44 39) 50%);
    border: none;
    height: 47px;
    width: 70px;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 20px;
}

.t-2 {
    padding-top: 10px;
}

.maindis {
    display: flex;
    justify-content: space-between;
}

.violet-btn {
    background: rgb(141 77 181);
    border: none;
    height: 47px;
    width: 70px;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 20px;
}



.small-green-btn {
    background-color: #2FB11D;
    border: none;
    height: 47px;
    width: 70px;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 20px;
}

.small-red-btn {
    background-color: #E32E27;
    border: none;
    height: 47px;
    width: 70px;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 20px;

}

.violet-green-btn {
    background: linear-gradient(to right bottom, rgb(141 77 181) 50%, rgb(47 177 29) 50%);
    border: none;
    height: 47px;
    width: 70px;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 20px;

}

.table-h-div {
    background-color: #E4E4E4;
    width: 100%;
    height: 37px;
    color: #243A48;
    font: 13px;
    font-family: "mukta";
    font-weight: bold;


}

.table-inner-row {
    background-color: white;
    width: 100%;
    height: 45px;
    color: #243A48;
    font-size: 15px;
    font-family: "mukta";
    font-weight: 500;
    border-bottom: 1px solid #b0b5b8;
}

.red-dot {
    width: 20px;
    height: 20px;
    background-color: #E32E27;
    border-radius: 10px;
}

.violet-dot {
    width: 20px;
    height: 20px;
    background-color: rgb(141 77 181);
    border-radius: 10px;
}

.green-dot {
    width: 20px;
    height: 20px;
    background-color: #25c321;
    border-radius: 10px;


}

.plus-icon {
    height: 20px;
    width: 25px;
}

.fail {
    color: rgb(235, 44, 44) !important;
}

.success {
    color: #25c321;
}

.bet-menu-card {
    width: 100%;
    height: 370px;
    color: #243A48;
    font-size: 17px;
    font-family: "mukta";
    font-weight: 500;
}

.bet-menu-card-h {
    color: #0ecf9f;
    font-size: 18px;
    font-family: "mukta";


}

/* <<<<<<<<<<<<<<------------------------------ModalData-------------->>>>>>> */
.color-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;
}

.color-modal-container {
    position: absolute;
    width: 520px;
    height: 370px;
    /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
    background-color: #01a839;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 8px; */
    z-index: 999;


}

.model-h-dive {
    background-color: #CFD5D9;
    font-size: 17px;
    font-weight: bold;
    height: 43px;
}

.num-box {
    height: 40px;
    width: 80px;
    border: none;
    color: #9e9a9a;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: white;
    /* background-color: rgb(165, 152, 152); */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.num-box.is-activated {
    background-color: #9e9a9a !important;
    color: black;
}

.minus-div {
    width: 50px;
    height: 45px;
    border: none;
    color: #9e9a9a;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: white;
    font-size: 20px;
}

.plus-div {
    width: 50px;
    height: 45px;
    border: none;
    color: #9e9a9a;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: white;
    font-size: 20px;

}

.counter-data-div {
    width: 200px;
    height: 45px;
    color: black;
    font-size: 20px;

}

@media screen and (max-width: 500px) {
    .color-game-h p {
        font-size: 12px;
        ;
    }

    .color-modal-container {
        position: absolute;
        width: 400px;
        height: 370px;
        /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
        /* background-color: #01a839; */
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* border-radius: 8px; */
        z-index: 999;


    }

}


.innercontent {
    margin: 20px;
    border: 0.1rem solid White
}

.innerheading {
    border-bottom: 0.1rem solid white;
}

.borderintop {
    padding: 8px 10px 0px;
    border-bottom: 0.1rem solid white;
}

.paymettext {
    border-right: 0.1rem solid white;

}


.modallist {
    border-top: 0.1rem solid white;

    /* gap: 0.3rem; */
    overflow: auto;
    white-space: nowrap;
}
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 17px;
    font-weight: 700;
  }
  
  .loader-container {
    position: absolute;
    z-index: 1000;
    background-color: #000000de;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  