.center-div{
    /* background-color: #202020; */
    height: 89vh;
    /* width: 50%; */
    overflow-y: scroll;

}
.center-div::-webkit-scrollbar{
    display:none;
}

.MainArea{
    background-color: #202020;
    min-height: 300px !important;
    
    width: 100%;
    margin-top: 10px;
   
}
.sport-div{
    background-color: #353434;
    height: 80px;
    width: 100%;
    /* overflow: hidden; */
    /* overflow-x: scroll; */
   
    /* overflow-x: scroll; */
  

}
.sport-div::-webkit-scrollbar{
    display:none;
}
.single-sport-div{
    height: 80px;
    width: 90px;
    color: white !important;
    font-family: "mukta";
    /* border: 1px solid white !important;; */

}
.single-sport-div.is-active{
    background-color: #312f2f;
}
.round-sport-div{
    height: 45px;
    width: 45px;
    border-radius: 50px;
    background-color: #5a5858;
}
.round-sport-div.is-activ{
    background-color:#43CEED;
}
.sport-img{
    height: 30px;
    width: 30px;
}
.num-div{
    background-color: white;
    color: black;
    border-radius: 3px;
    height: 12px;
    margin-top: 2px;
    font-size: 10px;
    width: 12px;
}
.num-div p{
    font-size: 14px;
}
/* -------------------matchdata----------- */
.match-div{
    border-bottom: 1px solid #5a5858;
    min-height: 70px;
    max-height: auto;
    background-color: #202020;
}
.sport-name{
    font-size: 13px;
    font-weight: 600;
}
.sport-name.is-act{
    color:#43CEED;
}
.schedule-btn{
    background-color: #5a5858;
    height: 45px;
    width: 80px;
    color: white;
    font-size: 11px;
    border-radius: 5px;
}
.in-play-btn{
    background-color: #030303;
    height: 45px;
    width: 80px;
    color: #fd7e14;
    border: 1px solid #fd7e14;

    font-size: 10px;
    border-radius: 5px;
    
}


.play-icon{
    color: #fd7e14;
    /* background-color: #fa5004; */
     height: 9px;
    width: 9px; 
    
}
.match-name{
    color:white;
    font-family: "mukta";
    font-weight: 500;
}
.tournamnet-name{
    color: grey;
    font-size: 12px;
}
.vsd{
    color:#fd740d;
}
.b1-btn{
    background-color:#202020;
    border:  1px solid #43CEED;
    color:#43CEED;
    font-size: 10px;
    height: 23px;
    width: 30px;
    border-radius: 4px;
}
.f9-btn{

    background-color:#202020;
    border:  1px solid #43CEED;
    color:#43CEED;
    font-size: 10px;
    height: 23px;
    width: 30px;
    border-radius: 4px;

}
.blue-btn{
    background-color:rgb(84, 84, 253) !important;
    color: white !important;
    border: none;
    border-radius: 4px;
    height:30px;
    width:42%;
    font-family: "mukta";
    font-weight: 500;



}
.orange-btn{
    background-color:#e27e32 !important;
    color: white !important;
    border: none;
    border-radius: 4px;
    height:30px;
    width:42%;
    font-family: "mukta";
    font-weight: 500;
}
.image-size{
    height:53px;
    width: 60px;
}
.a-image-size{
    height: 32px;
    width: 62px;
}
.image-div{
    height: 40px;
    width: 66px;
}
.blink-img-div{
    height: 57px;
    width: 65px;
}
@media screen and (max-width: 700px){
    .blue-btn{
        width: 60px;
        margin-left: 5px;
        margin-bottom: 3px;
    }
    .orange-btn{
        width: 60px;
        margin-bottom: 3px;
    }


}
@media screen and (max-width: 450px){
    .blue-btn{
        width: 36px;
        margin-left: 15px;
        margin-bottom: 3px;
        
    }
    .orange-btn{
        width: 36px;
        margin-bottom: 3px;
    }


}

 .samllcardsection .card {
    background: linear-gradient(45deg, rgba(3, 23, 21, 0.3411764706), rgba(3, 23, 21, 0.3098039216)) !important;
    /* background-color: #000; */
    border: 1px solid #fd740d;
    transition: all 0.3s ease-in-out;
}

.samllcardsection .card .card-body .cardimage {
    width: 50px;
    height: 50px;
}

.samllcardsection .card .card-body .card_heading {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}

.samllcardsection .card .card-body .sub_heading {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    text-align: end;
}

.imgcolor{
    background-color: white;
}