.funds-transfer-main-div{
    background-color: rgb(20, 19, 19);
    min-height: 86vh;
    width: 100%;
    max-height: auto;
    border-radius: 5px;

}
.F-h{
    font-weight: 700;
    color: white ;
    font-size: 20px;
    
    
}
.form-area{
    border-top: 1px solid rgb(66, 61, 61);
}
.deposit-card{
    height: 370px;
    width: 500px;
    background-color: black;
    border: 1px solid rgb(99, 96, 96);
    color: white;
}
.balance-div{
    
}
.inst{
    color: white;
    font-size: 14px;
}
.step-1{
    color:#fa5004;
    font-size: 17px;
    font-weight: 600;
    font-family: "mukta";
}
label{
    font-size: 11px;

}
/* .user-id-input{
    background-color:rgb(102, 146, 146);
} */

.deposit-f-btn{
    width: 100%;
    background-color: #fa5004;
    color: white;
}
.deposit-f-btn:hover{
    background-color: #fa5004 !important;
}
@media screen and (max-width: 600px){
    .deposit-card{
        height: 500px;
        width: 340px;
        background-color: black;
        border: 1px solid rgb(99, 96, 96);
        color: white;
    }
    .inst{
        color: white;
        font-size: 9px;
    }
    .step-1{
        color:#fa5004;
        font-size: 9px;
        font-weight: 600;
        font-family: "mukta";
    }
    .deposit-btn{
        width: 100%;
        background-color: #fa5004;
        color: white;
    }


}

@media screen and (max-width: 490px){
    .deposit-card{
        height: 500px;
        width: 340px;
        background-color: black;
        border: 1px solid rgb(99, 96, 96);
        color: white;
    }
    .inst{
        color: white;
        font-size: 12px;
    }
    .step-1{
        color:#fa5004;
        font-size: 13px;
        font-weight: 600;
        font-family: "mukta";
    }


}
