body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: #E039FD !important;
}

.navbar-toggler,
.btn-close {
  background-color: aliceblue !important;
}

.navbar-brand {
  padding-left: 15px !important;
}

@media screen and (max-width: 320px) {
  .marquesection .marquee .inner a {
    margin-right: 0;
    padding: 10px;
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .joinOurSection .sectionHeading {
    font-size: 32px !important;
  }

  .nscbsj {
    margin-bottom: 20px;
  }

  .marquesection {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .jkjsoqi {
    margin-top: 30px;
  }
  .marquesection .marquee .inner a {
    margin-right: 0;
  }
  .marquesection .marquee .inner a span {
    width: 40px;
    height: 40px;
  }

  .navbar-brand {
    padding-left: 0px !important;
  }
}
@media (min-width: 551px) and (max-width: 950px) {
  footer .socilaicon li a {
    width: 44px;
    height: 44px;
  }

  .navbar-brand {
    padding-left: 0px !important;
  }
}
