.home{
    overflow-y: scroll;
    /* height:88vh; */
    /* background-color: aqua !important; */
}
.home::-webkit-scrollbar{
    display:none;
}


.Heading{
    color: #da7619;
}