.Pbox{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffeee7;
    /* background-image: linear-gradient(to bottom right, #686868, #a1a1a1); */
    border-radius: 10px;
    margin-bottom: 30px;
    margin: 10px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
}

.float-right {
    float: right !important;
}