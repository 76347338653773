.Aviator-main-div{
    background-color: #181717;
    height:75vh;
    width: 100%;
    border-radius: 7px;
}
.small-btns-div{
    width: 100%;
    overflow:hidden;
}
.aviator-h-div{
    color:#43CEED;
    font-family: "mukta";
}
.aviator-h-div p{
    font-size: 16px;
    font-weight: 500;
}
.small-g-btn{
  background-color:  #0a5b6d;
  height: 25px;
  width: 50px;
  border-radius: 13px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  


}
.small-v-btn{

    background-color:  rgb(141 77 181);
    height: 25px;
    width: 50px;
    border-radius: 13px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.canvas-main-div{
    width: 100%;
    border: 1px solid rgb(44, 43, 43);
    height: 180px;
    border-radius: 20px;
    position:relative;
    overflow: hidden;
    /* animation: rotateBackground 20s linear infinite;  */
}
.canvas-main-div::before {
    content: "";
    background: url('../../Media/bg-rotate-old.svg'); /* Replace 'your-image.jpg' with the URL or path to your image */
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 200%; /* Make sure the background covers the whole area even when rotated */
    height: 200%;
    z-index: -1;
    /* animation: rotateBackground 20s linear infinite;  */
  }
  
  .content {
    /* Your content styling */
  }
  
  @keyframes rotateBackground {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
.bet-btn-main-div{
    width: 100%;
    border: 1px solid rgb(44, 43, 43);
    height: 180px;
    border-radius: 20px;
    background: url(../../Media//grey-texture-bg.png); 
    /* background-size: 100% 100%;   */
    background-repeat:repeat;
    background-size:auto;




}
.counter-div{
    background-color: black;
    width: 160px;
    border-radius: 20px;
    height:35px;
    border: 1px solid rgb(63, 62, 62)
}
.p-icon{
    height: 20px;
    font-size: 20px;
    background-color:rgb(63, 62, 62) ;
    border-radius: 12px;
    color: black;

    

}
.m-icon{
    height:20px;
    font-size: 20px;
    background-color:rgb(63, 62, 62) ;
    border-radius: 12px;
    color:black
}
.count-div{
    font-size: 20px;
    width: 70%;


}
.Amount-btn{
    background-color:rgb(58, 57, 57) ;
    height: 23px;
    width: 70px;
    border-radius: 9px;
    color: white;
    font-size: 15px;
    font-family: "mukta";
    border:none;
}
.Amount-btn:hover{
    background-color: rgb(73, 71, 71);
}
.bet-button{
    height: 95px;
    width: 250px;
    border-radius: 20px;
    background-color:   #25c321 ;
    border: none;
  
    color: white;
    font-weight: 600;

}
@media screen and (max-width: 490px){
    .bet-button{
        height: 95px;
        width: 170px;
        border-radius: 20px;
        background-color:   #25c321 ;
        border: none;
      
        color: white;
        font-weight: 600;
    
    }
    .counter-div{
        background-color: black;
        width: 130px;
        border-radius: 20px;
        height:35px;
        border: 1px solid rgb(63, 62, 62)
    }
    .Amount-btn{
        background-color:rgb(58, 57, 57) ;
        height: 23px;
        width: 55px;
        border-radius: 9px;
        color: white;
        font-size: 15px;
        font-family: "mukta";
        border:none;
    }
   


}

